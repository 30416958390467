<template>
  <div>
    <h2>Credit Card Information</h2>

    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <button class="btn btn-primary mb-3" v-b-modal.update-payment-modal>
            Add Payment Method
          </button>
        </div>
        <div class="list-group list-group-flush">
          <div
            class="list-group-item bg-white"
            v-for="card in paymentMethods"
            :key="`stored-card-${card.paymentProfileId}`"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <a href="#">
                  <span
                    v-if="card.cardType"
                    :class="[
                      'payment',
                      'payment-dark',
                      `payment-provider-${card.cardType.toLowerCase()}-dark`,
                      'payment-xs',
                      'me-2',
                    ]"
                  ></span>
                </a>
              </div>
              <div class="col text-truncate">
                <span class="text-reset d-block">
                  {{ splitWords(card.cardType) }} ending in
                  {{ card.cardNumber.slice(-4) }}
                </span>
                <div class="d-block text-muted text-truncate mt-n1">
                  Expires {{ card.expirationDate }}
                </div>
              </div>
              <div class="col-auto" v-if="card.default">
                <a
                  href="#"
                  class="list-group-item-actions"
                  title="Default payment card"
                  v-b-tooltip.hover
                  ><!-- Download SVG icon from http://tabler-icons.io/i/star -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon text-muted"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-card-modal @saved="reloadCards"></add-card-modal>
  </div>
</template>

<script>
import BillingService from "../../../services/BillingService";
import AddCardModal from "./AddCardModal.vue";

export default {
  name: "account.billing.billing",
  components: {
    AddCardModal,
  },
  data() {
    return {
      paymentMethods: [],
    };
  },
  methods: {
    splitWords(word) {
      if (word) {
        return word.split(/(?=[A-Z\s]+)/).join(" ");
      } else {
        return "";
      }
    },
    async reloadCards() {
      this.paymentMethods = await BillingService.getPaymentMethods(
        this.$auth.activeCompany
      );
    },
  },
  async mounted() {
    await this.reloadCards();
  },
};
</script>
