<template>
  <b-modal size="lg" id="update-payment-modal">
    <template #modal-header="{ close }">
      <h5 class="modal-title">Add Payment Method</h5>
      <button type="button" class="btn-close" @click.prevent="close()"></button>
    </template>

    <div class="alert alert-danger" v-if="error">
      <strong>
        There has been an error authorizing the card you entered.
      </strong>
      Please check your card details and try again.
    </div>

    <billing-details-form v-model="details"></billing-details-form>

    <template #modal-footer="{ ok, cancel }">
      <a
        href="#"
        @click.prevent="cancel()"
        class="btn btn-link link-secondary"
        data-bs-dismiss="modal"
      >
        Cancel
      </a>
      <a
        href="#"
        @click.prevent="save(ok)"
        :class="['btn', 'btn-primary', 'ms-auto', valid ? '' : 'disabled']"
        v-if="!saving"
      >
        <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        <span>Save</span>
      </a>
      <a
        href="#"
        :class="['btn', 'btn-primary', 'ms-auto', 'disabled']"
        @click.prevent
        v-else
      >
        <span
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        Saving
      </a>
    </template>
  </b-modal>
</template>

<script>
import BillingService from "../../../services/BillingService";
import BillingDetailsForm from "./BillingDetailsForm.vue";
import TrackingService from "../../../services/TrackingService";

export default {
  components: { BillingDetailsForm },
  name: "account.billing.add-card-modal",
  data() {
    return {
      saving: false,
      error: false,
      details: {
        company: null,
        cardValid: false,
        cardNumber: null,
        cardCode: null,
        expiration: null,
        fullName: null,
        firstName: null,
        lastName: null,
        address: null,
        region: null,
        country: null,
        zip: null,
      },
    };
  },

  computed: {
    valid() {
      return (
        this.details.cardValid &&
        this.details.cardNumber &&
        this.details.cardCode &&
        this.details.expiration &&
        this.details.firstName &&
        this.details.lastName &&
        this.details.zip &&
        this.details.address
      );
    },
  },

  methods: {
    async save(ok) {
      this.details.company = this.$auth.activeCompany;
      this.saving = true;
      let add = await BillingService.addPaymentMethod(this.details);
      TrackingService.track("added a new saved card");

      if (add !== "") {
        this.$emit("saved");
        ok();
        this.$nextTick(() => (this.saving = false));
      } else {
        this.error = true;
        this.saving = false;
      }
    },
  },
};
</script>
